
import {DataManager,  UrlAdaptor } from "@syncfusion/ej2-data";
import { EncHelper } from "./EncHelper";

 class custom extends UrlAdaptor {
    beforeSend(dm:any, request:any) { 
        let tk = EncHelper.getToken();
        console.log(tk)
        request.setRequestHeader("Token",tk);
    }

    
}
export class MyGridOption{
     //url need to be change as per development and production server
  // private static Base_Url = "https://localhost:44300/api/AdminGrid/";
   private static Base_Url = "https://alphalt.tinker.ly/api/AdminGrid/";
 //private static Base_Url = "https://lt.tinker.ly/api/AdminGrid/";
        static getDataManager(data: string) {
        return new DataManager({
            url: this.Base_Url + data ,
            adaptor: new custom(),
            crossDomain: true, 
            insertUrl:this.Base_Url + data+"Add",
            removeUrl:this.Base_Url + data+"Delete",
            updateUrl: this.Base_Url + data + "Edit",
            
        });
      
    }
}